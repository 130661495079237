import React from "react";
import HomeSOne from "../Components/Pages/Home/Home_S_One";
import HomeSTwo from "../Components/Pages/Home/Home_S_Two";
import HomeSThree from "../Components/Pages/Home/Home_S_Three";
import HomeSFour from "../Components/Pages/Home/Home_S_Four";
import HomeSFive from "../Components/Pages/Home/Home_S_Five";
import { Helmet } from "react-helmet";
import { FormattedMessage } from "react-intl";

function Home() {
  return (
    <div className="margin-top">
      <FormattedMessage defaultMessage="Home" id="Title.Home">
        {(title) => (
          <Helmet>
            <title>{title}</title>
          </Helmet>
        )}
      </FormattedMessage>
      <HomeSOne />
      <HomeSTwo />
      <HomeSThree />
      <HomeSFour />
      <HomeSFive />
    </div>
  );
}

export default Home;
