import React, { useEffect } from "react";
import Routes from "./Routes/Routes";
import "aos/dist/aos.css";
import AOS from "aos";

const App = () => {
  useEffect(() => {
    AOS.init({
      duration: 800,
      once: true,
    });
  }, []);

  return (
    <div className="App">
      <Routes />
    </div>
  );
};

export default App;
