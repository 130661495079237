import React from "react";

function Single_Language(props) {
  return (
    <div
      className={`single-language flex align-center ${props.styles}`}
      onClick={props.handleLanguageChange}
    >
      <img src={props.flag} alt="flag" />
      <span>{props.language}</span>
    </div>
  );
}

export default Single_Language;
