import React from "react";
import Person1 from "../../../Assets/Images/about-us_person-1.jpg";
import Person2 from "../../../Assets/Images/about-us_person-2.jpg";
import Person3 from "../../../Assets/Images/about-us_person-3.jpg";
import { ReactComponent as FacebookIcon } from "../../../Assets/Icons/facebook-color.svg";
import { ReactComponent as InstagramIcon } from "../../../Assets/Icons/instagram-color.svg";
import { ReactComponent as TwitterIcon } from "../../../Assets/Icons/twitter-color.svg";
import { FormattedMessage } from "react-intl";

function About_S_Two() {
  return (
    <div className="about_s_two">
      <div className="aboutSTwo_text">
        <h1 data-aos="fade-up">
          <FormattedMessage
            defaultMessage="Familientraditionen"
            id="About.S2.H1"
          />
        </h1>
        <p data-aos="fade-up" data-aos-delay="250">
          <FormattedMessage
            defaultMessage="Der Beruf, den wir heute ausüben, ist das Spiel, mit dem wir seit unserer Kindheit aufgewachsen sind. Beruf seit Jahrzehnten in unserer Familie vererbt, für mehrere Generationen. Dies ist einer der Schlüsselpunkte für unseren Erfolg, ohne jedoch unsere Loyalität und unser Engagement in all dieser Zeit zu vernachlässigen, die uns geholfen haben, in diesem Bereich führend zu sein."
            id="About.S2.P"
          />
        </p>
      </div>
      <div
        className="aboutSTwo_content flex evenly flex-wrap"
        data-aos="fade-up"
        data-aos-delay="250"
      >
        <div
          className="one-card flex column align-center"
          data-aos="fade-up"
          data-aos-delay="300"
        >
          <img src={Person1} alt={Person1} />
          <p className="name">Isak Aliu</p>
          <p className="details">
            <FormattedMessage
              defaultMessage="Der Großvater"
              id="About.S2.Person1"
            />
          </p>
          <div className="socials flex between">
            <a
              href="http://facebook.com"
              target="_blank"
              rel="noopener noreferrer"
              data-aos="fade-up"
              data-aos-delay="800"
            >
              <FacebookIcon />
            </a>
            <a
              href="http://instagram.com"
              target="_blank"
              rel="noopener noreferrer"
              data-aos="fade-up"
              data-aos-delay="500"
            >
              <InstagramIcon />
            </a>
            <a
              href="http://twitter.com"
              target="_blank"
              rel="noopener noreferrer"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              <TwitterIcon />
            </a>
          </div>
        </div>
        <div
          className="one-card flex column align-center"
          data-aos="fade-up"
          data-aos-delay="40e0"
        >
          <img src={Person2} alt={Person2} />
          <p className="name">Hysen Aliu</p>
          <p className="details">
            <FormattedMessage
              defaultMessage="Der Vater"
              id="About.S2.Person2"
            />
          </p>
          <div className="socials flex between">
            <a
              href="http://facebook.com"
              target="_blank"
              rel="noopener noreferrer"
              data-aos="fade-up"
              data-aos-delay="800"
            >
              <FacebookIcon />
            </a>
            <a
              href="http://instagram.com"
              target="_blank"
              rel="noopener noreferrer"
              data-aos="fade-up"
              data-aos-delay="500"
            >
              <InstagramIcon />
            </a>
            <a
              href="http://twitter.com"
              target="_blank"
              rel="noopener noreferrer"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              <TwitterIcon />
            </a>
          </div>
        </div>
        <div
          className="one-card flex column align-center"
          data-aos="fade-up"
          data-aos-delay="500"
        >
          <img src={Person3} alt={Person3} />
          <p className="name">Shkëlzen Aliu</p>
          <p className="details">
            <FormattedMessage defaultMessage="Ich" id="About.S2.Person3" />
          </p>
          <div className="socials flex between">
            <a
              href="http://facebook.com"
              target="_blank"
              rel="noopener noreferrer"
              data-aos="fade-up"
              data-aos-delay="800"
            >
              <FacebookIcon />
            </a>
            <a
              href="http://instagram.com"
              target="_blank"
              rel="noopener noreferrer"
              data-aos="fade-up"
              data-aos-delay="500"
            >
              <InstagramIcon />
            </a>
            <a
              href="http://twitter.com"
              target="_blank"
              rel="noopener noreferrer"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              <TwitterIcon />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About_S_Two;
