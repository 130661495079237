import React from "react";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import { FormattedMessage } from "react-intl";
import Image1 from "../../../Assets/Images/image_1.jpg";
import Image2 from "../../../Assets/Images/image_2.jpg";
import { Link } from "react-router-dom";

const rows_data = [
  {
    titleDefaultMessage: "Neubau",
    titleID: "Home.S4.T2",
    images: [
      <div className="item" data-value="1">
        <img src={Image1} alt="/" />
      </div>,
      <div className="item" data-value="2">
        <img src={Image2} alt="/" />
      </div>,
      <div className="item" data-value="3">
        <img src={Image1} alt="/" />
      </div>,
      <div className="item" data-value="4">
        <img src={Image2} alt="/" />
      </div>,
      <div className="item" data-value="5">
        <img src={Image1} alt="/" />
      </div>,
    ],
    className: "new_buildings",
  },
  {
    titleDefaultMessage: "Sanierung",
    titleID: "Home.S4.T3",
    images: [
      <div className="item" data-value="1">
        <img src={Image2} alt="/" />
      </div>,
      <div className="item" data-value="2">
        <img src={Image1} alt="/" />
      </div>,
      <div className="item" data-value="3">
        <img src={Image2} alt="/" />
      </div>,
      <div className="item" data-value="4">
        <img src={Image1} alt="/" />
      </div>,
      <div className="item" data-value="5">
        <img src={Image2} alt="/" />
      </div>,
    ],
    className: "old_buildings",
  },
];

const responsive = {
  0: { items: 1 },
  568: { items: 2 },
  1024: { items: 3 },
};

function Home_S_Four() {
  return (
    <div className="home_s_four flex column align-center">
      <h1 className="title" data-aos="fade-up">
        <FormattedMessage
          defaultMessage="Einige unserer Arbeiten"
          id="Home.S4.T1"
        />
      </h1>
      {rows_data.map((item, index) => {
        return (
          <div
            className={`homeSFour_row flex column align-center ${rows_data[index].className}`}
            key={index}
          >
            <h2 className="rows_title" data-aos="fade-up" data-aos-delay="100">
              <FormattedMessage
                defaultMessage={item.titleDefaultMessage}
                id={item.titleID}
              />
            </h2>
            <div
              className="rows_images flex align-center between"
              data-aos="fade-up"
            >
              <AliceCarousel
                mouseTracking
                items={item.images}
                responsive={responsive}
                controlsStrategy="alternate"
              />
            </div>
          </div>
        );
      })}
      <Link className="homeSFour_button " to="/projekte" data-aos="fade-up">
        <FormattedMessage defaultMessage="Weitere Beispiele" id="Home.S4.T4" />
      </Link>
    </div>
  );
}

export default Home_S_Four;
//
