import React from "react";
import emailjs from "emailjs-com";

const inputsData = [
  {
    title: "Name",
    type: "text",
    name: "name",
    placeholder: "Your Name",
    id: "name",
  },
  {
    title: "Phone",
    type: "tel",
    name: "phone",
    placeholder: "Your Phone Number",
    id: "phone",
  },
  {
    title: "Email",
    type: "email",
    name: "email",
    placeholder: "Email Address",
    id: "email",
  },
];

function Contact_Form() {
  function sendEmail(e) {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_rstfzqb",
        "template_uifhob6",
        e.target,
        "user_Tbb5QObzOlYoMyLpcBjd8"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
  }

  return (
    <form className={`contactSOne_SendEmailSide`} onSubmit={sendEmail}>
      {inputsData.map((item, index) => {
        return (
          <div className="input_group" key={index}>
            <p data-aos="fade-right">{item.title} *</p>
            <input
              type={item.type}
              name={item.name}
              placeholder={item.placeholder}
              required={true}
              data-aos="fade-left"
              id={item.id}
            />
          </div>
        );
      })}
      <div className="input_group">
        <p data-aos="fade-right">Message *</p>
        <textarea
          name="message"
          placeholder="Please add your message here"
          required={true}
          rows="6"
          data-aos="fade-left"
        />
      </div>
      <input
        type="submit"
        value="Send Message"
        className="sendButton"
        data-aos="fade-left"
      />
    </form>
  );
}

export default Contact_Form;
