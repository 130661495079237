import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";
import SelectLanguage from "../Select_Languages/Select_Language";
import { FormattedMessage } from "react-intl";
import { openOrCloseMobNav } from "../../../Store/Actions";

function Mobile_Navbar(props) {
  const dispatch = useDispatch();
  const open_or_close_mobnav = useSelector(
    (state) => state.open_or_close_mobnav
  );
  return (
    <div
      className={`mobile_navbar flex column between ${open_or_close_mobnav}`}
    >
      <div className="mobile_navbar_links flex column align-center">
        <NavLink to="/" onClick={() => dispatch(openOrCloseMobNav())}>
          <FormattedMessage defaultMessage="Home" id="NavLink.Home" />
        </NavLink>
        <NavLink to="/projekte" onClick={() => dispatch(openOrCloseMobNav())}>
          <FormattedMessage defaultMessage="Projekte" id="NavLink.Projects" />
        </NavLink>
        <NavLink to="/uberuns" onClick={() => dispatch(openOrCloseMobNav())}>
          <FormattedMessage defaultMessage="Über uns" id="NavLink.AboutUs" />
        </NavLink>
        <NavLink to="/kontakt" onClick={() => dispatch(openOrCloseMobNav())}>
          <FormattedMessage defaultMessage="Kontakt" id="NavLink.Contact" />
        </NavLink>
      </div>
      <SelectLanguage
        setLanguage={props.setLanguage}
        language={props.language}
      />
    </div>
  );
}

export default Mobile_Navbar;
