import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { openOrCloseMobNav } from "../../../Store/Actions";

function HamburgerToX() {
  const open_or_close_mobnav = useSelector(
    (state) => state.open_or_close_mobnav
  );
  const dispatch = useDispatch();

  return (
    <div
      className={`navTrigger ${open_or_close_mobnav && "active"}`}
      onClick={() => dispatch(openOrCloseMobNav())}
      id="myBtn"
    >
      <i></i>
      <i></i>
      <i></i>
    </div>
  );
}

export default HamburgerToX;
