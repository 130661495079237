import React, { useState, useEffect } from "react";
import throttle from "lodash.throttle";
import { Link, NavLink } from "react-router-dom";
import logo from "../../../Assets/Logo-Bauservice.png";
import SelectLanguage from "../Select_Languages/Select_Language";
import HamburgerToX from "../Hamburger_To_X/Hamburger_To_X";
import { FormattedMessage } from "react-intl";

function Header(props) {
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = throttle(() => {
      if (window.scrollY >= 50) setScrolled(true);
      else setScrolled(false);
    }, 50);
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <header className={`header flex between align-center ${scrolled}`}>
      <div
        className="header_icon"
        data-aos="fade-right"
        data-aos-duration="1000"
      >
        <Link to="/">
          <img src={logo} alt="logo" />
        </Link>
      </div>
      <div
        className="header_links flex between"
        data-aos="fade-down"
        data-aos-duration="1000"
      >
        <NavLink to="/" exact>
          <FormattedMessage defaultMessage="Home" id="NavLink.Home" />
        </NavLink>
        <NavLink to="/projekte">
          <FormattedMessage defaultMessage="Projekte" id="NavLink.Projects" />
        </NavLink>
        <NavLink to="/uberuns">
          <FormattedMessage defaultMessage="Über uns" id="NavLink.AboutUs" />
        </NavLink>
        <NavLink to="/kontakt">
          <FormattedMessage defaultMessage="Kontakt" id="NavLink.Contact" />
        </NavLink>
      </div>
      <div
        className="header_contact_language flex column"
        data-aos="fade-left"
        data-aos-duration="1000"
      >
        <div className="header_contact-cont flex column justify-center align-end text-right">
          <a href="tel:+41764277161">+41764277161</a>
          <a href="mailto:info@bauservice-as.ch">info@bauservice-as.ch</a>
        </div>
        <div className="header-contact-lang">
          <SelectLanguage
            setLanguage={props.setLanguage}
            language={props.language}
          />
        </div>
      </div>
      <div className="header_hamburger">
        <HamburgerToX />
      </div>
    </header>
  );
}

export default Header;
