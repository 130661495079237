import React from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";

function Home_S_Five() {
  return (
    <div className="home_s_five flex column align-center justify-center">
      <div className="title">
        <h1>
          <FormattedMessage
            defaultMessage="Lassen Sie uns gemeinsam an Ihrem nächsten Bauprojekt arbeiten."
            id="Home.S5.T1"
          />
        </h1>
      </div>
      <Link to="/kontakt" data-aos="fade-up">
        <FormattedMessage defaultMessage="Kontaktiere uns" id="Home.S5.T2" />
      </Link>
    </div>
  );
}

export default Home_S_Five;
