import React, { Component } from "react";
import { ReactComponent as ArrowUp } from "../../../Assets/Icons/arrow-up.svg";

export default class Scroll_To_Top_Icon extends Component {
  constructor(props) {
    super(props);
    this.state = {
      height: window.innerHeight,
      bottomReached: false,
    };
    this.handleScroll = this.handleScroll.bind(this);
  }
  handleScroll() {
    const windowHeight =
      "innerHeight" in window
        ? window.innerHeight
        : document.documentElement.offsetHeight;
    const body = document.body;
    const html = document.documentElement;
    const docHeight = Math.max(
      body.scrollHeight,
      body.offsetHeight,
      html.clientHeight,
      html.scrollHeight,
      html.offsetHeight
    );
    const windowBottom = windowHeight + window.pageYOffset;
    if (windowBottom + 350 >= docHeight) {
      this.setState({
        bottomReached: true,
      });
    } else {
      this.setState({
        bottomReached: false,
      });
    }
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  scrollTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  render() {
    return (
      <div
        className={`scrollTopIcon ${this.state.bottomReached}`}
        onClick={this.scrollTop}
      >
        <ArrowUp />
      </div>
    );
  }
}
