import React, { useState } from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import Header from '../Components/Shared/Header/Header'
import MobileNavbar from '../Components/Shared/Mobile_Navbar/Mobile_Navbar'
import Footer from '../Components/Shared/Footer/Footer'
import ComingSoon from '../Pages/Coming_Soon'
import ScrollToTop from './Scroll_To_Top'
import ScrollToTopIcon from '../Components/Shared/Scroll_To_Top_Icon/Scroll_To_Top_Icon'
import Home from '../Pages/Home'
import Projects from '../Pages/Projects'
import AboutUs from '../Pages/About_Us'
import Contact from '../Pages/Contact'
import { dataLocalStorage } from '../local_storage'
import { IntlProvider } from 'react-intl'
import { translate } from '../Translations/'
import Cookies from '../Components/Shared/Cookies/Cookies'

function Routes() {
  const [language, setLanguage] = useState(
    dataLocalStorage.getLocale() || 'de-DE'
  )

  const [cookies, setCookies] = useState(dataLocalStorage.getCookies())

  return (
    <div>
      <BrowserRouter>
        <IntlProvider
          locale={language}
          formats={{ number: 'de' }}
          messages={translate[language]}
        >
          <ScrollToTop>
            <Header language={language} setLanguage={setLanguage} />
            <MobileNavbar language={language} setLanguage={setLanguage} />
            <Switch>
              <Route exact path="/" component={Home} />
              <Route exact path="/projekte" component={Projects} />
              <Route exact path="/uberuns" component={AboutUs} />
              <Route exact path="/kontakt" component={Contact} />
              <Route path="*" component={ComingSoon} />
            </Switch>
            <Footer />
            <Cookies cookies={cookies} setCookies={setCookies} />
            <ScrollToTopIcon />
          </ScrollToTop>
        </IntlProvider>
      </BrowserRouter>
    </div>
  )
}

export default Routes
