import React from "react";
import ContactSOne from "../Components/Pages/Contact/Contact_S_One";
import ContactSTwo from "../Components/Pages/Contact/Contact_S_Two";
import { Helmet } from "react-helmet";
import { FormattedMessage } from "react-intl";

function Contact() {
  return (
    <div>
      <FormattedMessage
        defaultMessage="Bauservice-AS | Kontakt"
        id="Title.Contact"
      >
        {(title) => (
          <Helmet>
            <title>{title}</title>
          </Helmet>
        )}
      </FormattedMessage>
      <ContactSOne />
      <ContactSTwo />
    </div>
  );
}

export default Contact;
