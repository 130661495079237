import React from "react";
import { FormattedMessage } from "react-intl";

function Home_S_Three() {
  return (
    <div className="home_s_three flex evenly flex-wrap">
      <div className="homeSThree_text first" data-aos="fade-up">
        <h1>
          <FormattedMessage
            defaultMessage="Mit einer langen Tradition in dieser Arbeit garantieren wir Genauigkeit in unserer Arbeit und Treue in der getroffenen Vereinbarung"
            id="Home.S3.T1"
          />
        </h1>
      </div>
      <div className="homeSThree_text second" data-aos="fade-up">
        <h1>
          <FormattedMessage
            defaultMessage="Wir arbeiten im Neubau, in hohen Gebäuden oder auch in kleinen Gebäuden. Wir beschäftigen uns aber auch mit der Sanierung von Altbauten"
            id="Home.S3.T2"
          />
        </h1>
      </div>
    </div>
  );
}

export default Home_S_Three;
