import React, { useState, useCallback } from 'react'
import ImageViewer from 'react-simple-image-viewer'
import { Link } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import Image2021_1 from '../../../Assets/Images/projects-2021-1.jpeg'
import Image2021_2 from '../../../Assets/Images/projects-2021-2.jpeg'
import Image2021_3 from '../../../Assets/Images/projects-2021-3.jpeg'
import Image2021_4 from '../../../Assets/Images/projects-2021-4.jpeg'
import Image2021_5 from '../../../Assets/Images/projects-2021-5.jpeg'
import Image2021_6 from '../../../Assets/Images/projects-2021-6.jpeg'
import Image2020_1 from '../../../Assets/Images/projects-2020-1.jpeg'
import Image2020_2 from '../../../Assets/Images/projects-2020-2.jpeg'
import Image2020_3 from '../../../Assets/Images/projects-2020-3.jpeg'
import Image2020_4 from '../../../Assets/Images/projects-2020-4.jpeg'

const rows_data = [
  {
    year: '2021',
    images: [
      Image2021_1,
      Image2021_2,
      Image2021_3,
      Image2021_4,
      Image2021_5,
      Image2021_6,
    ],
  },
  {
    year: '2020',
    images: [
      Image2020_1,
      Image2020_2,
      Image2020_3,
      Image2020_4,
      Image2021_2,
      Image2021_1,
    ],
  },
  {
    year: '2019',
    images: [
      Image2020_1,
      Image2020_2,
      Image2020_3,
      Image2020_4,
      Image2021_2,
      Image2021_1,
    ],
  },
]

function Projects_S_One() {
  const [currentImage, setCurrentImage] = useState(0)
  const [isViewerOpen, setIsViewerOpen] = useState(false)

  const openImageViewer = useCallback((index) => {
    setCurrentImage(index)
    setIsViewerOpen(true)
  }, [])

  const closeImageViewer = () => {
    setCurrentImage(0)
    setIsViewerOpen(false)
  }

  return (
    <div className="projects_s_one flex column ">
      <div className="top">
        <h1 className="top-title" data-aos="fade-down">
          <FormattedMessage defaultMessage="Projekte" id="NavLink.Projects" />
        </h1>
        <h3 className="top-subtitle" data-aos="fade-right">
          <FormattedMessage
            defaultMessage="Die lange Zeit, seit wir diesen Beruf ausüben, hat es uns ermöglicht, an vielen verschiedenen Projekten zu arbeiten. Und ihre große Zahl erlaubt es uns nicht, sie alle hier darzustellen. Aber unten zeigen wir Ihnen die neuesten Arbeiten, die wir gemacht haben."
            id="Projects.S1.Title"
          />
        </h3>
      </div>
      <div className="bottom">
        {rows_data.map((item, index) => {
          return (
            <div className="row flex column" key={index}>
              <h1 data-aos="fade-right">
                <FormattedMessage defaultMessage="Jahr " id="Year" />
                {item.year}
              </h1>
              <div className="images flex between flex-wrap">
                {item.images.map((i, y) => {
                  return (
                    <div className="image" key={y}>
                      {/* <ModalImage
                        small={item.images[y]}
                        large={item.images[y]}
                        alt="To Close, press X or ESC"
                      /> */}
                      <img
                        src={item.images[y]}
                        onClick={() => openImageViewer(y)}
                        alt={item.images[y]}
                      />

                      {isViewerOpen && (
                        <ImageViewer
                          src={item.images}
                          currentIndex={currentImage}
                          onClose={closeImageViewer}
                          disableScroll={false}
                          backgroundStyle={{
                            backgroundColor: 'rgba(0,0,0,0.9)',
                          }}
                          closeOnClickOutside={true}
                        />
                      )}
                    </div>
                  )
                })}
              </div>
            </div>
          )
        })}
        <Link to="/kontakt" data-aos="fade-up" className="button">
          <FormattedMessage defaultMessage="Kontaktiere uns" id="Home.S5.T2" />
        </Link>
      </div>
    </div>
  )
}

export default Projects_S_One
