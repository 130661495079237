import React from "react";
import { FormattedMessage } from "react-intl";

function Home_S_One() {
  return (
    <div className="home_s_one flex column align-center justify-center">
      <h1 className="quote" data-aos="fade-down">
        <FormattedMessage
          defaultMessage="Der Preis des Erfolges ist Hingabe, harte Arbeit und unablässiger Einsatz für das, was man erreichen will."
          id="Banner.H1"
        />
      </h1>
      <h1 className="name" data-aos="fade-up" data-aos-duration="2000">
        "Frank Lloyd Wright"
      </h1>
    </div>
  );
}

export default Home_S_One;
