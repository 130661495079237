import React from "react";
import { ReactComponent as CheckedIcon } from "../../../Assets/Icons/checked.svg";
import { ReactComponent as ClockIcon } from "../../../Assets/Icons/clock.svg";
import { ReactComponent as LikeIcon } from "../../../Assets/Icons/like.svg";
import { FormattedMessage } from "react-intl";

const cardsData = [
  {
    icon: <CheckedIcon />,
    text: (
      <FormattedMessage defaultMessage="Qualität über alles" id="Home.S2.C1" />
    ),
    data_aos: "fade-right",
  },
  {
    icon: <ClockIcon />,
    text: (
      <FormattedMessage
        defaultMessage="Im Budget und pünktlich"
        id="Home.S2.C2"
      />
    ),
    data_aos: "flip-up",
  },
  {
    icon: <LikeIcon />,
    text: (
      <FormattedMessage
        defaultMessage="Sei am Ende glücklich"
        id="Home.S2.C3"
      />
    ),
    data_aos: "fade-left",
  },
];

function Home_S_Two() {
  return (
    <div className="home_s_two flex align-center evenly flex-wrap">
      {cardsData.map((item, index) => {
        return (
          <div
            className="homeSTwo_card flex column align-center"
            key={index}
            data-aos={item.data_aos}
            data-aos-duration="1000"
          >
            <div className="icon flex align-center justify-center">
              {item.icon}
            </div>
            <div className="text">
              <h2>{item.text}</h2>
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default Home_S_Two;
