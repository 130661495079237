import React from "react";
import AboutSOne from "../Components/Pages/About_Us/About_S_One";
import AboutSTwo from "../Components/Pages/About_Us/About_S_Two";
import { Helmet } from "react-helmet";
import { FormattedMessage } from "react-intl";

function About_Us() {
  return (
    <div>
      <FormattedMessage
        defaultMessage="Bauservice-AS | Über uns"
        id="Title.AboutUs"
      >
        {(title) => (
          <Helmet>
            <title>{title}</title>
          </Helmet>
        )}
      </FormattedMessage>
      <AboutSOne />
      <AboutSTwo />
    </div>
  );
}

export default About_Us;
