import React, { useState } from "react";
import { useDispatch } from "react-redux";
import germanFlag from "../../../Assets/Icons/germany.svg";
import ukFlag from "../../../Assets/Icons/uk.svg";
import albanianFlag from "../../../Assets/Icons/albania.svg";
import { dataLocalStorage } from "../../../local_storage";
import { ReactComponent as ArrowDown } from "../../../Assets/Icons/arrow-down.svg";
import SingleLanguage from "./Single_Language";
import { openOrCloseMobNav } from "../../../Store/Actions";

function Select_Language(props) {
  const dispatch = useDispatch();
  const languages = [
    {
      value: "de-DE",
      lang: "German",
      flag: germanFlag,
    },
    {
      value: "en-UK",
      lang: "English",
      flag: ukFlag,
    },
    {
      value: "sq-AL",
      lang: "Albanian",
      flag: albanianFlag,
    },
  ];

  const handleLanguageChange = (language, locale) => {
    props.setLanguage(locale);
    dataLocalStorage.setLanguage(language);
    dataLocalStorage.setLocale(locale);
  };

  let flag = null;
  switch (props.language) {
    case "de-DE":
      flag = germanFlag;
      break;
    case "en-UK":
      flag = ukFlag;
      break;
    case "sq-AL":
      flag = albanianFlag;
      break;
    default:
      flag = germanFlag;
  }

  const [dropdownOpen, setDropdownOpen] = useState(false);

  return (
    <div className={`select-language flex align-center ${dropdownOpen}`}>
      <img src={flag} alt="selected flag" />
      {dataLocalStorage.getLanguage() || "German"}
      <ArrowDown
        className="arrow"
        onClick={() => {
          if (window.innerWidth < 769) {
            setDropdownOpen(!dropdownOpen);
          }
        }}
      />
      <div className="select-languge_dropdown">
        {languages
          .sort(function (a, b) {
            var lang1 = a.lang.toUpperCase();
            var lang2 = b.lang.toUpperCase();
            if (lang1 < lang2) {
              return 1;
            }
            if (lang1 > lang2) {
              return -1;
            }
            return 0;
          })
          .map((thisLang, i) => {
            if (dataLocalStorage.getLanguage() !== thisLang.lang) {
              return (
                <SingleLanguage
                  value={thisLang.value}
                  key={i}
                  handleLanguageChange={() => {
                    handleLanguageChange(thisLang.lang, thisLang.value);
                    if (window.innerWidth < 769) {
                      dispatch(openOrCloseMobNav());
                      setDropdownOpen(false);
                    }
                  }}
                  styles={thisLang.value === props.language && "selected"}
                  language={thisLang.lang}
                  flag={thisLang.flag}
                />
              );
            } else {
              return null;
            }
          })}
      </div>
    </div>
  );
}

export default Select_Language;
