import React from "react";
import ProjectsSOne from "../Components/Pages/Projects/Projects_S_One";
import { Helmet } from "react-helmet";
import { FormattedMessage } from "react-intl";

function Projects() {
  return (
    <div className="projects">
      <FormattedMessage
        defaultMessage="Bauservice-AS | Projekte"
        id="Title.Projects"
      >
        {(title) => (
          <Helmet>
            <title>{title}</title>
          </Helmet>
        )}
      </FormattedMessage>
      <ProjectsSOne />
    </div>
  );
}

export default Projects;
