import React from "react";
import { ReactComponent as FacebookIcon } from "../../../Assets/Icons/facebook-color.svg";
import { ReactComponent as InstagramIcon } from "../../../Assets/Icons/instagram-color.svg";
import { ReactComponent as TwitterIcon } from "../../../Assets/Icons/twitter-color.svg";
import ContactForm from "./Contact_Form";
import { FormattedMessage } from "react-intl";

function Contact_S_Two() {
  return (
    <div className="contact_s_two flex between align-center">
      <div className="contactSOne_SocialsSide">
        <h1 data-aos="fade-right">
          <FormattedMessage
            defaultMessage="Senden Sie uns eine Nachricht!"
            id="Contact.S2.T1"
          />
        </h1>
        <div className="icons flex column between align-center">
          <a
            href="http://facebook.com"
            target="_blank"
            rel="noopener noreferrer"
            data-aos="fade-right"
            data-aos-delay="800"
          >
            <FacebookIcon />
            <p>Facebook</p>
          </a>
          <a
            href="http://instagram.com"
            target="_blank"
            rel="noopener noreferrer"
            data-aos="fade-right"
            data-aos-delay="500"
          >
            <InstagramIcon />
            <p>Instagram</p>
          </a>
          <a
            href="http://twitter.com"
            target="_blank"
            rel="noopener noreferrer"
            data-aos="fade-right"
            data-aos-delay="200"
          >
            <TwitterIcon />
            <p>Twitter</p>
          </a>
        </div>
      </div>
      <ContactForm />
    </div>
  );
}

export default Contact_S_Two;
