import React from 'react'
import { ReactComponent as CheckedIcon } from '../../../Assets/Icons/checked.svg'
import { FormattedMessage } from 'react-intl'

const cardsData = [
  {
    icon: <CheckedIcon />,
    text: <FormattedMessage defaultMessage="Ruf uns an" id="Contact.S1.C1" />,
    details: 'Shkelzen Aliu',
    link: '+41764277161',
    href: 'tel:+41764277161',
    data_aos: 'fade-right',
  },
  {
    icon: <CheckedIcon />,
    text: <FormattedMessage defaultMessage="Email" id="Contact.S1.C2" />,
    link: 'info@bauservice-as.ch',
    href: 'mailto:info@bauservice-as.ch',
    data_aos: 'fade-right',
  },
  {
    icon: <CheckedIcon />,
    text: <FormattedMessage defaultMessage="Büro" id="Contact.S1.C3" />,
    href: 'https://goo.gl/maps/55DzC7YL4twG72ee8',
    link: 'Bahnhofstrasse 41 8854 Siebnen',
    data_aos: 'fade-right',
  },
]

function Contact_S_One() {
  return (
    <div className="contact_s_one">
      <div
        className="contactSOne_cards flex  evenly flex-wrap"
        data-aos="fade-right"
        // data-aos-duration="50"
      >
        {cardsData.map((item, index) => {
          return (
            <a
              href={item.href}
              className="contactSOne_card flex column align-center justify-center"
              key={index}
              target="blank"
              // data-aos={item.data_aos}
              // data-aos-duration="1000"
            >
              <div className="icon flex align-center justify-center">
                {item.icon}
              </div>
              <div className="text">
                <h2>{item.text}</h2>
              </div>
              <p>
                <a target="blank" href={item.href}>
                  {item.link}
                </a>
              </p>
            </a>
          )
        })}
      </div>
      <div className="contactSOne_map">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d173558.7019450388!2d9.13493!3d47.180281!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x479aca21ce99feef%3A0x344a82b5902243d3!2sBahnhofstrasse%2041%2C%208854%20Wangen%2C%20Switzerland!5e0!3m2!1sen!2sus!4v1623438052543!5m2!1sen!2sus"
          allowFullScreen=""
          loading="lazy"
          title="Map"
        />
      </div>
    </div>
  )
}

export default Contact_S_One
