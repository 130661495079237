import React from "react";
import PlasteringOne from "../../../Assets/Images/about-us_plastering-1.jpg";
import PlasteringTwo from "../../../Assets/Images/about-us_plastering-2.jpg";
import { FormattedMessage } from "react-intl";

function About_S_One() {
  return (
    <div className="about_s_one flex column align-center">
      <h1 className="title" data-aos="fade-down">
        <FormattedMessage defaultMessage="Über uns" id="NavLink.AboutUs" />
      </h1>
      <div className="one-row first flex between align-center">
        <div className="image" data-aos="fade-right">
          <img src={PlasteringOne} alt={PlasteringOne} />
        </div>
        <div className="text" data-aos="fade-left">
          <h2 className="text-title">
            <FormattedMessage
              defaultMessage="Unsere Geschichte"
              id="About.S1.R1.T"
            />
          </h2>
          <p>
            <FormattedMessage
              defaultMessage="Wir geben vom Zahn der Zeit geschädigten Fassaden ein neues Gesicht und bringen mit verschiedensten Gipstechniken italienisches Flair, anheimelnde Landhausatmosphäre oder kühle Eleganz in Ihre vier Wände. Unser 5 Mitarbeiter-starkes Team beherrscht von der Spachteltechnik bis zur feinsten Lackierung das gesamte Spektrum des Gipser-Malerhandwerks. Zu unseren weiteren Fachgebieten zählen Wärmedämmarbeiten und Betonsanierungen."
              id="About.S1.R1.P"
            />
          </p>
        </div>
      </div>
      <div className="one-row second flex between align-center">
        <div className="image" data-aos="fade-left">
          <img src={PlasteringTwo} alt={PlasteringTwo} />
        </div>
        <div className="text" data-aos="fade-right">
          <h2 className="text-title">
            <FormattedMessage
              defaultMessage="Die ganze vielfalt des gipserandwerks"
              id="About.S1.R2.T"
            />
          </h2>
          <p>
            <FormattedMessage
              defaultMessage="Das Gipserhandwerk war schon immer facettenreich – doch mit den großen Fortschritten im technischen Bereich und dem Wunsch vieler Kunden möglichst viele Handwerksleistungen aus einer Hand zu erhalten, hat sich auch unser Leistungsspektrum immens erweitert. Von der Verarbeitung frisch auf dem Markt erschienener Beschichtungssysteme bis zur fachgerechten Montage neu entwickelter Wärmedämmverbundsysteme."
              id="About.S1.R2.P"
            />
          </p>
        </div>
      </div>
    </div>
  );
}

export default About_S_One;
