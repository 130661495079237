import React from "react";
// import "../../pages/main.css";
import Countdown from "react-countdown-now";

const ComingSoon = () => {
  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      return <span>You are good to go!</span>;
    } else {
      return (
        <div className="coming-soon_date flex evenly">
          <h3 className="flex column align-center">
            Days
            <span> {days} </span>
          </h3>
          <h3 className="flex column align-center">
            Hours
            <span> {hours} </span>
          </h3>
          <h3 className="flex column align-center">
            Minutes
            <span> {minutes} </span>
          </h3>
          <h3 className="flex column align-center">
            Seconds
            <span> {seconds} </span>
          </h3>
        </div>
      );
    }
  };

  return (
    <div className="coming-soon flex column align-center justify-center ">
      <h1>
        Our website is under construction.We`ll be here soon with our new
        awesome site
      </h1>
      <Countdown date={Date.now() + 200000000} renderer={renderer} />
    </div>
  );
};

export default ComingSoon;
