import React from 'react'
import { ReactComponent as FacebookIcon } from '../../../Assets/Icons/facebook.svg'
import { ReactComponent as InstagramIcon } from '../../../Assets/Icons/instagram.svg'
import { ReactComponent as TwitterIcon } from '../../../Assets/Icons/twitter.svg'
import { ReactComponent as LinkedinIcon } from '../../../Assets/Icons/linkedin.svg'
import { Link } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'

function Footer() {
  return (
    <div className="footer">
      <div className="footer_top-section flex evenly flex-wrap">
        <div className="footer_top_s_row social-media">
          <p className="title">
            <FormattedMessage defaultMessage="Social Media" id="Social.Media" />
          </p>
          <div className="content flex column">
            <a
              href="http://facebook.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FacebookIcon />
              Facebook
            </a>
            <a
              href="http://instagram.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <InstagramIcon />
              Instagram
            </a>
            <a
              href="http://twitter.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <TwitterIcon />
              Twitter
            </a>
            <a
              href="http://linkedin.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <LinkedinIcon />
              Linkedin
            </a>
          </div>
        </div>
        <div className="footer_top_s_row quick-links">
          <p className="title">
            <FormattedMessage defaultMessage="Quick Links" id="Quick.Links" />
          </p>
          <div className="content flex column">
            <Link to="/">
              <FormattedMessage defaultMessage="Home" id="NavLink.Home" />
            </Link>
            <Link to="/projekte">
              <FormattedMessage
                defaultMessage="Projekte"
                id="NavLink.Projects"
              />
            </Link>
            <Link to="/uberuns">
              <FormattedMessage
                defaultMessage="Über uns"
                id="NavLink.AboutUs"
              />
            </Link>
            <Link to="/kontakt">
              <FormattedMessage defaultMessage="Kontakt" id="NavLink.Contact" />
            </Link>
          </div>
        </div>
        <div className="footer_top_s_row contact-us">
          <p className="title">
            <FormattedMessage defaultMessage="Contact Us" id="Contact.Us" />
          </p>
          <div className="content flex column">
            <Link to="/" className="disabled-link">
              Bauservice-AS
            </Link>
            <Link to="/" className="disabled-link same-width-on-mobile">
              Bahnhofstrasse 41 8854 Siebnen
            </Link>
            <a href="tel:+41764277161">Tel - +41764277161</a>
            <a
              href="mailto:info@bauservice-as.ch"
              className="same-width-on-mobile"
            >
              Email - info@bauservice-as.ch
            </a>
          </div>
        </div>
      </div>
      <div className="footer_bottom-section">
        <hr />
        <p>
          Bauservice-AS ©
          <FormattedMessage
            defaultMessage=" All Right Reserved by "
            id="Right.Reserved"
          />
          it-wellen.de
        </p>
      </div>
    </div>
  )
}

export default Footer
